<template>
  <div
    v-if="submitted"
    class="max-w-screen-md"
  >
    <PAlert
      class="alert"
      type="success"
    >
      {{ $tk('NewCustomer.ConfirmText') }}
    </PAlert>
  </div>
  <div
    v-else-if="error"
    class="max-w-screen-md"
  >
    <PAlert
      class="alert"
      type="error"
    >
      <span>{{ $tk('NewCustomer.ErrorText') }}</span> <a :href="'mailto:' + supportEmail">{{ supportEmail }}</a>
    </PAlert>
  </div>
  <div
    v-else
    class="max-w-screen-md"
  >
    <PAlert
      class="alert"
      type="info"
    >
      <span>{{ $tk('NewCustomer.InfoText') }} {{ $tk('NewCustomer.SendRequestTo') }} </span>
      <a :href="'mailto:' + supportEmail">{{ supportEmail }}</a>
      <span> {{ $tk('NewCustomer.OrCallUsOn') }} </span>
      <a :href="supportPhoneTel">{{ supportPhone }}</a>!
    </PAlert>
    <form
      v-if="page === 0"
      @submit.prevent="goToPage(1)"
    >
      <div class="mt-8 md:flex md:space-x-10 space-y-8 md:space-y-0">
        <div class="w-full md:w-1/2">
          <h2 class="font-medium upper">{{ $tk('NewCustomer.CompanyInformation') }}</h2>
          <PInput
            class="mt-4 w-full"
            :label="$tk('NewCustomer.VatNo')"
            v-model="vat"
            :required="true"
          />
          <PInput
            class="mt-2 w-full"
            :label="$tk('NewCustomer.CompanyName')"
            v-model="companyName"
            :required="true"
          />
          <PInput
            class="mt-2 w-full"
            :label="$tk('NewCustomer.Address')"
            v-model="locationAddress"
            :required="true"
          />
          <div class="mt-2 w-full flex flex-row gap-2">
            <PInput
              class="mt-2 w-24"
              :label="$tk('NewCustomer.PostCode')"
              v-model="locationPostcode"
              :required="true"
            />
            <PInput
              class="mt-2 flex-grow"
              :label="$tk('NewCustomer.PostPlace')"
              v-model="locationPostplace"
              :required="true"
            />
          </div>
          <PSelect
            class="mt-2 w-full"
            :label="$tk('NewCustomer.Country')"
            :items="countries"
            itemText="name"
            itemValue="id"
            v-model="locationCountry"
            :required="true"
          />
        </div>
        <div class="w-full md:w-1/2">
          <h2 class="font-medium upper">{{ $tk('NewCustomer.ContactPerson') }}</h2>
          <PInput
            class="mt-4 w-full"
            :label="$tk('NewCustomer.Name')"
            v-model="contactName"
            :required="true"
          />
          <PInput
            class="mt-2 w-full"
            :label="$tk('NewCustomer.Email')"
            type="email"
            v-model="contactEmail"
            :required="true"
            :error="showFormErrors && !validEmail ? 'Ugyldig e-post' : undefined"
          />
          <PInput
            class="mt-2 w-full"
            :label="$tk('NewCustomer.Phone')"
            v-model="contactPhone"
            :required="true"
          />
        </div>
      </div>
      <div class="mt-8 flex items-center justify-end space-x-4">
        <router-link
          class="text-sm hover:underline"
          to="login"
        >{{ $tk('Common.Actions.Cancel') }}</router-link>
        <PButton
          type="submit"
          color="primary"
        >
          <span>{{ $tk('Common.General.Next') }}</span>
        </PButton>
      </div>
    </form>

    <form
      v-else-if="page === 1"
      @submit.prevent="goToPage(2)"
    >
      <div class="mt-8 flex flex-col md:flex-row md:space-x-10">
        <div class="md:w-1/3">
          <h2 class="font-medium upper">{{ $tk('NewCustomer.InvoiceInformation') }}</h2>
          <PInput
            class="mt-4 w-full"
            :label="$tk('NewCustomer.InvoiceReference')"
            v-model="invoiceReference"
          />
          <PInput
            class="mt-4 w-full"
            :label="$tk('NewCustomer.InvoiceEmail')"
            type="email"
            v-model="invoiceEmail"
            :required="true"
            :error="showFormErrors && !validInvoiceEmail ? 'Ugyldig e-post' : ''"
          />
          <PToggle
            class="mt-4"
            :label="$tk('NewCustomer.DifferentInvoiceAddress')"
            v-model="hasSeparateInvoiceAddress"
          />
          <PInput
            class="mt-2 w-full"
            :label="$tk('NewCustomer.Recipient')"
            v-model="invoiceName"
            :disabled="!hasSeparateInvoiceAddress"
          />
          <PInput
            class="mt-2 w-full"
            :label="$tk('NewCustomer.DifferentInvoiceAddress')"
            v-model="invoiceAltAddress"
            :disabled="!hasSeparateInvoiceAddress"
          />
          <PInput
            class="mt-2 w-24"
            :label="$tk('NewCustomer.PostCode')"
            v-model="invoicePostCode"
            :disabled="!hasSeparateInvoiceAddress"
          />
          <PInput
            class="mt-2 w-full"
            :label="$tk('NewCustomer.PostPlace')"
            v-model="invoicePostplace"
            :disabled="!hasSeparateInvoiceAddress"
          />
          <PSelect
            class="mt-2 w-full"
            :label="$tk('NewCustomer.Country')"
            :items="countries"
            itemText="name"
            itemValue="id"
            v-model="invoiceCountry"
            :disabled="!hasSeparateInvoiceAddress"
            :clearable="true"
          />
        </div>
        <div class="md:w-2/3">
          <h2 class="font-medium upper">{{ $tk('NewCustomer.Location') }}</h2>
          <div class="flex flex-col md:flex-row md:space-x-10">
            <div class="w-1/2">
              <PLabel
                class="mt-4"
                :label="$tk('NewCustomer.ApproximateNumberOfRTIsPerYear')"
              />
              <div
                v-for="(product, index) in products"
                :key="index"
                class="flex items-center space-x-3 mt-2"
              >
                <p-number
                  class="w-24 flex-shrink-0"
                  v-model="product.quantity"
                  :min="0"
                  :max="999999"
                />
                <img
                  :src="`${assetsBaseUrl}/media.ashx?${product.image}&width=30`"
                  :alt="'Produktbilde: ' + product.name"
                />
                <div class="text-sm">{{ product.name }}</div>
              </div>
            </div>
            <div class="md:w-1/2">
              <PLabel
                class="mt-4"
                :label="$tk('NewCustomer.OpeningHours')"
              />
              <div class="flex items-center">
                <PInput
                  class="w-20"
                  v-model="openingHoursFrom"
                  mask="##:##"
                  :masked="true"
                  placeholder="00:00"
                />
                <div class="px-2">{{ $tk('Common.General.To').toLowerCase() }}</div>
                <PInput
                  class="w-20"
                  v-model="openingHoursTo"
                  mask="##:##"
                  :masked="true"
                  placeholder="00:00"
                />
              </div>
              <div class="mt-4">
                <PSelect
                  :label="$t('Common.Location.NeedsLift')"
                  :items="needsLiftItems"
                  :required="true"
                  :error="showFormErrors && needsLift === '' ? 'Påkrevd felt' : ''"
                  v-model="needsLift"
                />
                <PSelect
                  class="mt-2"
                  :label="$t('Common.Location.HasRamp')"
                  :items="hasRampItems"
                  :required="true"
                  :error="showFormErrors && hasRamp === '' ? 'Påkrevd felt' : ''"
                  v-model="hasRamp"
                />
              </div>
              <PInput
                class="mt-4 w-full"
                :label="$tk('NewCustomer.Industry')"
                v-model="businessArea"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex items-center justify-end space-x-4">
        <router-link
          class="text-sm hover:underline"
          to="login"
        >{{ $tk('Common.Actions.Cancel') }}</router-link>
        <PButton
          @click="page = 0"
          color="secondary"
        >
          <span>{{ $tk('Common.General.Previous') }}</span>
        </PButton>
        <PButton
          type="submit"
          color="primary"
        >
          <span>{{ $tk('Common.General.Next') }}</span>
        </PButton>
      </div>
    </form>

    <template v-else>
      <div class="mt-8">
        <h2 class="font-medium upper">{{ $tk('NewCustomer.Need') }}</h2>
        <PTextarea
          class="w-full mt-4"
          v-model="comment"
          :label="$tk('NewCustomer.NeedText', true)"
          :disabled="isSubmitting"
        />
      </div>

      <div
        class="mt-8"
        v-if="agreementUserIdRequired"
      >
        <h2 class="font-medium upper">{{ $tk('NewCustomer.Confirmation') }}</h2>
        <PInput
          class="mt-4 w-full"
          :required="agreementUserIdRequired"
          :label="$tk('NewCustomer.Name')"
          v-model="agreementUserId"
        />
        <div class="flex mt-4 gap-1">
          <PCheckbox
            :label="$tk('NewCustomer.Accept')"
            :key="agreementUserIdRequired"
            v-model="agreementCustom"
          >
            (<a
              class="text-sm text-green-500 underline"
              href="https://www.smartretur.no/standard-avtalebetingelser"
              target="_blank"
            >{{ $tk('NewCustomer.Terms') }}</a>)
          </PCheckbox>
        </div>
      </div>
      <div class="mt-8 flex items-center justify-end space-x-4">
        <router-link
          class="text-sm hover:underline"
          to="login"
        >
          {{ $tk('Common.Actions.Cancel') }}
        </router-link>
        <PButton
          @click="page = 1"
          color="secondary"
          :disabled="isSubmitting"
        >
          <span>{{ $tk('Common.General.Previous') }}</span>
        </PButton>
        <PButton
          @click="submit()"
          :disabled="!canSubmit"
          color="primary"
          :loading="isSubmitting"
        >
          <span>{{ $tk('NewCustomer.SendRequest') }}</span>
        </PButton>
      </div>
    </template>
  </div>
</template>
<script>
import axios from "axios"
import { get, find } from "lodash"
import http from "@/http"

import { getConfig } from "@/config"
const config = getConfig()

import PLabel from '@/components/forms/partials/PLabel';

export default {
  name: "NewCustomer",
  components: { PLabel },
  data() {
    return {
      agreementCustom: false,
      agreementUserId: "",
      agreementUserIdRequired: config.agreementUserIdRequired,
      businessArea: "",
      comment: "",
      companyName: "",
      config: config,
      contactEmail: "",
      contactName: "",
      contactPhone: "",
      countries: [],
      error: false,
      hasRamp: '',
      hasSeparateInvoiceAddress: false,
      invoiceAltAddress: "",
      invoiceCountry: "",
      invoiceEmail: "",
      invoiceName: "",
      invoicePostCode: "",
      invoicePostplace: "",
      invoiceReference: "",
      isSubmitting: false,
      locationAddress: "",
      locationCountry: "NOR",
      locationPostcode: "",
      locationPostplace: "",
      needsLift: '',
      openingHoursFrom: "",
      openingHoursTo: "",
      page: 0,
      showFormErrors: false,
      status: undefined,
      submitted: false,
      vat: "",
    }
  },
  computed: {
    products() {
      return [
        { id: "TP", image: `/content/images/productimages/tp.png`, name: "Europall", quantity: 0 },
        { id: "EP", image: `/content/images/productimages/ep.png`, name: "Engangspall", quantity: 0 },
        { id: "HP", image: `/content/images/productimages/hp.png`, name: "Halvpall", quantity: 0 },
        { id: "LPR", image: `/content/images/productimages/sp.png`, name: "Storpall", quantity: 0 },
        { id: "HEK", image: `/content/images/productimages/hek.png`, name: "Helkarm", quantity: 0 },
        { id: "HAK", image: `/content/images/productimages/hak.png`, name: "Halvkarm", quantity: 0 },
      ]
    },
    canSubmit() {
      return !this.agreementUserIdRequired || (this.agreementUserIdRequired && this.agreementCustom && this.agreementUserId !== '');
    },
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    },
    supportEmail() {
      return this.status?.companySupportEmail || 'support@smartretur.no'
    },
    supportPhone() {
      return this.status?.companySupportPhone || '+47 975 67 000'
    },
    supportPhoneTel() {
      return 'tel:' + this.supportPhone.replace(/\s/g, '')
    },
    validEmail() {
      return this.contactEmail !== '' && /^[a-zA-Z0-9][-a-zA-Z0-9._]*@[-a-zA-Z0-9._]+[.][a-zA-Z0-9]{2,4}/.test(this.contactEmail)
    },
    validInvoiceEmail() {
      return this.invoiceEmail !== '' && /^[a-zA-Z0-9][-a-zA-Z0-9._]*@[-a-zA-Z0-9._]+[.][a-zA-Z0-9]{2,4}/.test(this.invoiceEmail)
    },
    needsLiftItems() {
      let arr = [
        { value: "0", text: this.$t("Common.General.No") },
        { value: "1", text: this.$t("Common.General.Yes") },
      ];
      if (this.needsLift === '') {
        arr.unshift({ value: "", text: this.$t("Common.General.NotSelected") });
      }
      return arr;
    },
    hasRampItems() {
      let arr = [
        { value: "0", text: this.$t("Common.General.No") },
        { value: "1", text: this.$t("Common.General.Yes") },
      ];
      if (this.hasRamp === '') {
        arr.unshift({ value: "", text: this.$t("Common.General.NotSelected") });
      }
      return arr;
    },
  },
  methods: {
    goToPage(page) {
      if ((page === 1 && this.validEmail) || (page === 2 && this.validInvoiceEmail && this.hasRamp !== '' && this.needsLift !== '')) {
        this.page = page
        this.showFormErrors = false
      } else {
        this.showFormErrors = true
      }
    },

    async submit() {

      this.isSubmitting = true

      let options = {
        customerName: this.companyName,
        locationName: this.companyName,
        locationId: "",
        locationStreet: this.locationAddress,
        locationCity: this.locationPostplace,
        locationZipCode: this.locationPostcode,
        locationCountry: this.locationCountry,
        userEmail: "",
        contactEmail: this.contactEmail,
        contactPhone: this.contactPhone,
        contactName: this.contactName,
        invoiceName: this.invoiceName,
        invoiceStreet: this.invoiceAltAddress,
        invoiceZipCode: this.invoicePostCode,
        invoiceCity: this.invoicePostplace,
        invoiceCountry: this.invoiceCountry,
        customerOrgNumber: this.vat,
        estimatedVolume: "0",
        invoiceRef: this.invoiceReference,
        invoiceEmail: this.invoiceEmail,
        invoiceLevel: "0",
        stockLocation: false,
        separateInvoiceAddress: this.hasSeparateInvoiceAddress,
        timeFrom: this.openingHoursFrom,
        timeTo: this.openingHoursTo,
        hasRamp: this.hasRamp === '1' ? true : this.hasRamp === '0' ? false : undefined,
        needsLift: this.needsLift === '1' ? true : this.needsLift === '0' ? false : undefined,
        portNumber: "",
        lineOfBusiness: this.businessArea,
        qtyEUR: find(this.products, function(item) { return item.id === "TP" }).quantity,
        qtyEUROne: find(this.products, function(item) { return item.id === "EP" }).quantity,
        qtyHalfPallets: find(this.products, function(item) { return item.id === "HP" }).quantity,
        qtyLargePallets: find(this.products, function(item) { return item.id === "LPR" }).quantity,
        qtyWholeFrames: find(this.products, function(item) { return item.id === "HEK" }).quantity,
        qtyHalfFrames: find(this.products, function(item) { return item.id === "HAK" }).quantity,
        comment: this.comment,
        agreementUserId: this.agreementUserId,
        agreementCustom: this.agreementCustom,
        token: Math.floor(Math.random() * 10000)
      }

      try {
        await http.post("CustomerReg", options)
        this.submitted = true
      } catch {
        this.error = true
      }

      this.isSubmitting = false

      return false
    }
  },
  watch: {
    vat: async function(val) {
      if (this.status.country !== "no") {
        return
      }
      if (val.length === 9) {
        const response = await axios.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${val}`)
          .then(response => response.data)
          .catch(() => { })

        this.companyName = get(response, "navn", "")
        this.locationAddress = get(response, "forretningsadresse.adresse", [])[0]
        this.locationPostcode = get(response, "forretningsadresse.postnummer", "")
        this.locationPostplace = get(response, "forretningsadresse.poststed", "")
      }
    }
  },
  async created() {
    this.status = await http.get("status")

    const response = await http.get("CustomerReg")
    console.log("response: ", response)
    if (response) {
      this.countries = response.countries
      this.agreementUserIdRequired = response.mustConfirm
    }
    this.locationCountry = this.countries.length ? this.countries.find(c => c.isDefault)?.id
      : "NOR"
  }
}
</script>

<style scoped></style>